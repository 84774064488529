<template>
  <div id="new" class="wrapper">
    <Header></Header>
    <el-main>
    <el-row :gutter="24">
      <!-- LEFT -->
      <el-col :xs="24" :sm="12" :md="10" class="layout-left">
        <el-row type="flex" align="middle">
          <el-col :span="8"><h4>{{lang.search}}</h4></el-col>
          <el-col><el-input :placeholder="lang.typeSearch" v-model="s.keyword" @keyup.enter.native="search(true)"></el-input></el-col>
        </el-row>
         <el-row type="flex" align="middle">
          <el-col :span="8"><h4>{{lang.tags}}</h4></el-col>
          <el-col>
            <el-select v-model="s.tags" multiple filterable allow-create default-first-option collapse-tags :placeholder="lang.typeTags">
              <el-option v-for="i in s.tags" :key="i" :label="i" :value="i"></el-option>
            </el-select>
          </el-col>
        </el-row>
         <el-row type="flex" align="middle">
          <el-col :span="8"><h4>{{lang.categories}}</h4></el-col>
          <el-col>
            <el-select v-model="s.category" filterable default-first-option :placeholder="lang.selectCategory">
              <el-option v-for="i in categories" :key="i._id" :label="i.name" :value="i._id"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!-- <el-tag effect="dark"> html </el-tag>
        <el-tag effect="plain"> css </el-tag>
        <el-tag effect="dark"> thu thuat </el-tag> -->
     
        <div>
          <el-button @click="search(true)" type="primary" plain >{{lang.search}}</el-button>
          <el-button @click="clear()" type="warning" plain >{{lang.clear}}</el-button>
        </div>
      </el-col>
      <!-- RIGHT -->
      <el-col :xs="24" :sm="12" :md="14" class="feed infinite-list-wrapper infinite-list">
        <vue-recyclist class="list" :list="posts" :size="size" :loadmore="load" :offset="300"  :debounceScroll="600">
        <template slot="item" scope="props">
          <div class="item" @contextmenu.prevent="openMenu(props, $event)">
            <el-card :body-style="{ padding: '0px' }" :class="props.data.image && props.data.image.length ? '' : 'noImage'" >
              <div class="el-image image cursor-p" v-if="props.data.image && props.data.image.length">
                <img @click="redirectDetailContent(props.data._id)" :src="props.data.image" class="el-image__inner" style="object-fit: cover"/>
              </div>
              <div class="info">
                <h4 style="font-size: 1.4rem" class="cursor-p"> <router-link :to="'/post/' + props.data._id">{{props.data.title}}</router-link></h4>
                <h4 class="cursor-p"><span>Chủ đề: </span> {{ getCategory(props.data.categoryId)}}</h4>  
                <div class="tags">
                  <span v-for="(i, index) in props.data.tags" :key="index">{{i}}</span>
                </div>
                <span class="date-time">{{convertDate(props.data.createTime)}}</span>
              </div>
          </el-card>
          </div>
        </template>
      </vue-recyclist>
      <!-- show option -->
      <ul class='contextmenu' v-show="visible" :style="{left:left+'px',top:top+'px'}">
        <li @click="editPost()">{{lang.edit}} {{lang.post}}</li>
      </ul>
      </el-col>
    </el-row>
    </el-main>
  </div>
</template>

<script>
import ENUM from "../../../const/enum";
import CONST from "../../../const/const";
import STOTE_KEY from "../../../const/storeKey";
import Header from "../../../components/Header";

// import VueRecyclist from "../../../components/VueRecyclist";
import VueRecyclist from 'vue-recyclist'
const {ACTIONS, _POST_DETAIL, _POSTS, _NEW} = STOTE_KEY
const ACTION = ACTIONS._NEW
export default {
  data() {
    return {
      size: 15,
      loading: false,
      isMax: false,
      isSearching: false,
      s: {
        tags: [],
        keyword: '',
        category: ''
      },
      visible: false,
      top: 0,
      left: 0,
      cacheIdToEdit: null,
    }
    },
  components: {
    Header,
    'vue-recyclist': VueRecyclist
  },
  created() {
    if(!this.categories.length){
      this.postJSON(ENUM.POSTS.GET_CATEGORY, {}, r => {
        const {ok, data} = r
        if(!ok) return this.message('Error', 'Không lấy được danh sách Chủ đề từ server')
        this.CHANGE_CATEGORIES(data)
      })
    }
  },
  mounted() {},
  methods:{
    load () {
      if(!this.isMax) {
        if(this.isSearching) return this.search();
        this.postJSON(ENUM.POSTS.GET_POSTS, { limit: this.size, from: this.posts.length }, r => {
          if(r.data.length < 15) this.isMax = true;
          let newPosts = [...this.posts, ...r.data]
          this.CHANGE_POSTS(newPosts)
        });
      }
      // neu call len sever = 0 thi -> max is true
    },
    async redirectDetailContent(_id){
      if(_id) await this.storeVue(_POST_DETAIL).dispatch(ACTIONS._POST_DETAIL.CHANGE_ID_POST, _id)
      this.$router.push('/post/' + _id)
    },
    search(check){
      // if(check) this.CHANGE_POSTS([]);
      /*
        check:neu la true -> bat dau search, else -> load() via scroll 
      */      
      if(!this.isSearching || check) this.CHANGE_POSTS([]);
  
      const { tags, keyword, category } = this.s
      
      if(tags.length || keyword || category){
        let d = {
          keyword: (keyword.trim()) || undefined,
          category: (category && category.trim()) || undefined,
          tags: tags.length ? tags : undefined,
          limit: this.limit,
          from: this.posts.length || 0
        }
        this.postJSON(ENUM.POSTS.SEARCH, d, r => {
          this.isSearching = true
          this.CHANGE_POSTS([...this.posts, ...r.data])
        })
      }
      
    },
    getCategory(_id){
      let category = this.categories.find(i=> i._id == _id)
      if(!category) return '...'
      return category.name
    },
    clear(){
      this.s = {
        tags: [],
        keyword: '',
        category: ''
      }
       this.postJSON(ENUM.POSTS.GET_POSTS, { limit: this.size, from: 0 }, (r) => {
        this.CHANGE_POSTS(r.data)
      });
    },
    convertDate(date){
      return CONST.convertDate(0, Number(date))
    },

    CHANGE_CATEGORIES(data){
      this.storeVue(_POSTS).dispatch(ACTIONS._POSTS.CHANGE_CATEGORIES, data)
    },
    CHANGE_POSTS(data){
      this.storeVue(_NEW).dispatch(ACTION.CHANGE_POSTS, data)
    },
    openMenu(post, e){
      this.cacheIdToEdit = post.data._id
      this.visible = true;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      this.left = e.clientX - offsetLeft + 15; // 15: margin right
      this.top = e.clientY;
    },
     closeMenu() {
      this.visible = false;
    },
    editPost(){
      this.$router.push('dashboard/posts/' + this.cacheIdToEdit)
    }
  },
  computed: {
    posts(){
      return this.storeVue(_NEW).getters.posts
    },
    categories(){
      return this.storeVue(_POSTS).getters.categories
    },
    lang(){ return this.langs() }
  },
   watch: {
    visible(value) {
      document.body[ value ? 'addEventListener' : 'removeEventListener' ]('click', this.closeMenu)
    },
  },
};
</script>

<style lang="scss" scoped>
// global
.cursor-p {
  cursor: pointer;
}
::-webkit-scrollbar {
    width: 4px;
    height: 7px;
}
::-webkit-scrollbar-thumb {
    &:hover {
      background: #67cccf;
    }
}

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 100;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
</style>
<style lang="scss">
#new {
  h4 {
    margin: 0;
  }
  .el-main {
    // padding: 3.5rem 0 0 0;
    padding: 0;
    .el-row {
      width: 100%;
      margin: 0 auto; // !important;
      .layout-left {
        > div {
          margin-top: 1rem;
          padding-left: .5rem;
          &:last-child{
            // padding-left: 2rem;
            margin-top: 1.5rem;
            text-align: center;
          }
        }
      }
    }
    .feed {
      padding-right: 0 !important;
      .list {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: calc(100vh - 55px);
        text-align: center;
        // background: #eee;
        .item {
          display: flex;
          padding: 10px 5px;
          width: 100%;
          text-align: left;
          p {
            margin: 0;
            word-wrap: break-word;
            font-size: 14px;
          }
          // css

          .el-card {
            width: 100%;
            max-width: 1200px !important;
            .el-card__body {
              padding: 0px !important;
              display: flex;
              max-height: 250px;
              // justify-content: space-evenly;
              .el-image {
                width: 100%;
                max-width: 45%;
              }
              .info {
                padding: 0.8rem 0.5rem 0 0.5rem;
                max-width: 55%;
                position: relative;
                flex: 1 0 0;
                h4 {
                  margin-bottom: 0.8rem;
                }
                .tags {
                  overflow-x: auto;
                  display: flex;
                  flex-wrap: nowrap;
                  width: 100%;
                  cursor: pointer;
                  margin-bottom: 25px;
                  span {
                    background: #dddddd;
                    padding: 2px 6px;
                    margin: 0 5px 5px;
                    white-space: nowrap;
                    border-radius: 5px;
                  }
                }
                .date-time {
                  position: absolute;
                  position: unset;
                  bottom: 0.2rem;
                  left: 0.5rem;
                  opacity: 0.5;
                  font-size: 0.9rem;
                }
              }
            }
          }
          .noImage {
            .el-card__body {
              .info {
                width: 100%;
                max-width: 100%;
                .date-time {
                  position: unset;
                  margin: 1rem 0 0.5rem 0;
                  display: block;
                }
              }
            }
          }
          img {
            width: 100%;
            max-height: 230px;
          }
          &:hover {
            .el-card {
              background: #e8e8e8;
            }
          }
        }
      }
    }
  }
}
</style>